import { CaretRight, CloseIcon, VerifiedIcon } from '../icons'
import { TypographyLabel } from '../typography'
import clsx from 'clsx'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import fallbackAvatarImage from '#/images/fallbackAvatar.webp'
import placeholderImage from '#images/image-placeholder.jpg'
import EditIcon from '../icons/Interface/EditIcon'

const ContentTile = ({
	title,
	description,
	image,
	isVerified,
	icon = <CaretRight />,
	showCaret = true,
	showBorder = true,
	variant = 'art',
	additionalClasses = '',
	fallbackImage = '',
	collectionType,
	handleEdit,
	handleDelete,
	collectionIndex,
	collectionEntryKey,
	styledBackground = false,
	elementRight,
	...props
}: {
	title: string
	description: string
	collectionType?: string
	image?: string
	isVerified?: boolean
	icon?: ReactNode
	showCaret?: boolean
	showBorder?: boolean
	variant?:
		| 'art'
		| 'collection'
		| 'user'
		| 'collectionEntry'
		| 'screenshotPreview'
	additionalClasses?: string
	fallbackImage?: string
	handleEdit?: (key: string) => void
	handleDelete?: (key: string) => void
	collectionIndex?: number
	collectionEntryKey?: string
	styledBackground?: boolean
	elementRight?: ReactNode
}) => {
	const derivedFallbackImage = fallbackImage
		? fallbackImage
		: variant === 'user'
			? fallbackAvatarImage
			: placeholderImage
	const imageRef = useRef<HTMLImageElement>(null)
	const [contentTileImage, setContentTileImage] = useState(
		image || derivedFallbackImage,
	)

	useEffect(() => {
		const imageEle = imageRef.current
		if (imageEle && imageEle.complete && imageEle.naturalWidth === 0) {
			setContentTileImage(derivedFallbackImage)
		}
	}, [])

	return (
		<div
			className={clsx(
				`group flex w-full rounded-md p-2`,
				showBorder &&
					'border border-textInactiveLight dark:border-textInactiveDark',
				showCaret
					? 'cursor-pointer hover:bg-uiTertiaryLight dark:hover:bg-uiTertiaryDark'
					: '',
				variant === 'collectionEntry'
					? 'bg-uiTertiaryLight dark:bg-uiTertiaryDark'
					: '',
				styledBackground ? 'bg-uiTertiaryLight dark:bg-uiTertiaryDark' : '',
				elementRight ? 'pr-4' : '',
				additionalClasses,
			)}
			{...props}
		>
			{image && (
				<div
					className={clsx(
						variant === 'art' && 'mr-2 h-20 min-h-20 w-20 min-w-20',
						variant === 'collection' && 'mr-2 h-14 min-h-14 w-14 min-w-14',
						variant === 'user' && 'h-12 min-h-12 w-12 min-w-12',
						'flex items-center justify-center',
					)}
				>
					<span
						className={clsx(
							variant === 'user' && 'group rounded-full',
							variant === 'user' &&
								showCaret &&
								'ring-offset-1 group-hover:ring-2 group-hover:ring-textPrimaryLight dark:group-hover:ring-textPrimaryDark',
							image === fallbackAvatarImage && 'rounded-full',
							'relative inline-block',
						)}
					>
						<img
							className={clsx(
								variant === 'art' && 'h-20 min-h-20 w-20 min-w-20',
								variant === 'collection' &&
									'h-14 min-h-14 w-14 min-w-14 rounded-md',
								variant === 'user' &&
									'h-12 min-h-12 w-12 min-w-12 rounded-full',
								variant === 'screenshotPreview' &&
									'h-14 min-h-14 w-14 min-w-14',
								'object-cover',
							)}
							src={contentTileImage}
							alt={title}
							ref={imageRef}
							onError={() => {
								setContentTileImage(derivedFallbackImage)
							}}
						/>
					</span>
				</div>
			)}
			<div
				className={clsx(
					variant === 'art' && 'w-full',
					variant === 'collection' && !elementRight && 'w-full',
					variant === 'user' && 'w-full',
					'mx-2 flex flex-grow flex-col justify-center gap-y-1 overflow-x-auto',
				)}
			>
				<div className="flex items-center gap-x-1 text-textPrimaryLight dark:text-textPrimaryDark">
					<TypographyLabel
						size="medium"
						isBold={true}
						additionalClasses={clsx(
							'text-textPrimaryLight dark:text-textPrimaryDark truncate text-left',
							showCaret && 'group-hover:underline',
						)}
					>
						{title}
						{title !== '' ? (
							collectionType ? (
								<span className="font-normal">: {collectionType}</span>
							) : (
								''
							)
						) : collectionType ? (
							<span className="font-normal">{collectionType}</span>
						) : (
							''
						)}
					</TypographyLabel>
					{isVerified && <VerifiedIcon size="small" />}
				</div>
				<TypographyLabel
					size="small"
					additionalClasses={clsx(
						'mr-3 text-labelSmallSemiBold text-textSecondaryLight dark:text-textSecondaryDark truncate text-left',
						showCaret &&
							'group-hover:text-textPrimaryLight dark:group-hover:text-textPrimaryDark',
					)}
				>
					{description}
				</TypographyLabel>
			</div>
			{variant !== 'collectionEntry' && variant !== 'screenshotPreview' ? (
				<div
					className={clsx(
						showCaret && icon ? 'w-6' : 'w-0',
						'flex flex-grow items-center justify-end text-grey500 group-hover:text-grey800 dark:text-grey400 dark:group-hover:text-white',
					)}
				>
					{showCaret && icon}
					{elementRight}
				</div>
			) : variant === 'collectionEntry' ? (
				<div className="flex">
					<div
						className={clsx(
							'w-6',
							'mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
						)}
					>
						{variant === 'collectionEntry' &&
							collectionEntryKey !== undefined &&
							handleEdit && (
								<div onClick={() => handleEdit(collectionEntryKey)}>
									<EditIcon />
								</div>
							)}
					</div>
					<div
						className={clsx(
							'w-6',
							'ml-4 mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
						)}
					>
						{variant === 'collectionEntry' &&
							collectionEntryKey !== undefined &&
							handleDelete && (
								<div onClick={() => handleDelete(collectionEntryKey)}>
									<CloseIcon />
								</div>
							)}
					</div>
				</div>
			) : variant === 'screenshotPreview' ? (
				<div
					className={clsx(
						'w-6',
						'ml-4 mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
					)}
				>
					{handleDelete && (
						<div onClick={() => handleDelete('delete')}>
							<CloseIcon />
						</div>
					)}
				</div>
			) : null}
			{variant === 'user' && handleDelete && (
				<div
					className={clsx(
						'w-6',
						'ml-4 mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
					)}
				>
					{handleDelete && (
						<div onClick={() => handleDelete('delete')}>
							<CloseIcon />
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default ContentTile

export const ContentTileWithoutImageFallback = ({
	title,
	description,
	image,
	isVerified,
	icon = <CaretRight />,
	showCaret = true,
	showBorder = true,
	variant = 'art',
	additionalClasses = '',
	fallbackImage = '',
	collectionType,
	handleEdit,
	handleDelete,
	collectionIndex,
	collectionEntryKey,
	styledBackground = false,
	...props
}: {
	title: string
	description: string
	collectionType?: string
	image?: string
	isVerified?: boolean
	icon?: ReactNode
	showCaret?: boolean
	showBorder?: boolean
	variant?:
		| 'art'
		| 'collection'
		| 'user'
		| 'collectionEntry'
		| 'screenshotPreview'
	additionalClasses?: string
	fallbackImage?: string
	handleEdit?: (key: string) => void
	handleDelete?: (key: string) => void
	collectionIndex?: number
	collectionEntryKey?: string
	styledBackground?: boolean
}) => {
	return (
		<div
			className={clsx(
				`group flex w-full rounded-md p-2`,
				showBorder &&
					'border border-textInactiveLight dark:border-textInactiveDark',
				showCaret
					? 'cursor-pointer hover:bg-uiTertiaryLight dark:hover:bg-uiTertiaryDark'
					: '',
				variant === 'collectionEntry'
					? 'bg-uiTertiaryLight dark:bg-uiTertiaryDark'
					: '',
				styledBackground ? 'bg-uiTertiaryLight dark:bg-uiTertiaryDark' : '',
				additionalClasses,
			)}
			{...props}
		>
			{image && (
				<div
					className={clsx(
						variant === 'art' && 'mr-2 h-20 w-20',
						variant === 'collection' && 'mr-2 h-14 w-14',
						variant === 'user' && 'h-12 w-12',
						'flex items-center justify-center',
					)}
				>
					<span
						className={clsx(
							variant === 'user' && 'group rounded-full',
							variant === 'user' &&
								showCaret &&
								'ring-offset-1 group-hover:ring-2 group-hover:ring-textPrimaryLight dark:group-hover:ring-textPrimaryDark',
							image === fallbackAvatarImage && 'rounded-full',
							'relative inline-block',
						)}
					>
						<img
							className={clsx(
								variant === 'art' && 'h-20 w-20',
								variant === 'collection' && 'h-14 w-14 rounded-md',
								variant === 'user' && 'h-12 w-12 rounded-full',
								variant === 'screenshotPreview' && 'h-14 w-14',
								'object-cover',
							)}
							src={image}
							alt={title}
						/>
					</span>
				</div>
			)}
			<div
				className={clsx(
					variant === 'art' && 'w-[calc(100%-125px)]',
					variant === 'collection' && 'w-[calc(100%-101px)]',
					variant === 'user' && 'w-[calc(100%-85px)]',
					'mx-2 flex flex-grow flex-col justify-center gap-y-1 overflow-x-auto',
				)}
			>
				<div className="flex items-center gap-x-1 text-textPrimaryLight dark:text-textPrimaryDark">
					<TypographyLabel
						size="medium"
						isBold={true}
						additionalClasses={clsx(
							'text-textPrimaryLight dark:text-textPrimaryDark text-left',
							showCaret && 'group-hover:underline',
						)}
					>
						{title}
						{collectionType ? (
							<span className="font-normal">: {collectionType}</span>
						) : (
							''
						)}
					</TypographyLabel>
					{isVerified && <VerifiedIcon size={'small'} />}
				</div>
				<TypographyLabel
					size="small"
					additionalClasses={clsx(
						'mr-3 text-labelSmallSemiBold text-textSecondaryLight dark:text-textSecondaryDark truncate text-left',
						showCaret &&
							'group-hover:text-textPrimaryLight dark:group-hover:text-textPrimaryDark',
					)}
				>
					{description}
				</TypographyLabel>
			</div>
			{variant !== 'collectionEntry' && variant !== 'screenshotPreview' ? (
				<div
					className={clsx(
						!showCaret && 'w-6',
						'flex flex-grow items-center justify-end text-grey500 group-hover:text-grey800 dark:text-grey400 dark:group-hover:text-white',
					)}
				>
					{showCaret && icon}
				</div>
			) : variant === 'collectionEntry' ? (
				<div className="flex">
					<div
						className={clsx(
							'w-6',
							'mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
						)}
					>
						{variant === 'collectionEntry' &&
							collectionEntryKey !== undefined &&
							handleEdit && (
								<div onClick={() => handleEdit(collectionEntryKey)}>
									<EditIcon />
								</div>
							)}
					</div>
					<div
						className={clsx(
							'w-6',
							'ml-4 mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
						)}
					>
						{variant === 'collectionEntry' &&
							collectionEntryKey !== undefined &&
							handleDelete && (
								<div onClick={() => handleDelete(collectionEntryKey)}>
									<CloseIcon />
								</div>
							)}
					</div>
				</div>
			) : variant === 'screenshotPreview' ? (
				<div
					className={clsx(
						'w-6',
						'ml-4 mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
					)}
				>
					{handleDelete && (
						<div onClick={() => handleDelete('delete')}>
							<CloseIcon />
						</div>
					)}
				</div>
			) : null}
			{variant === 'user' && handleDelete && (
				<div
					className={clsx(
						'w-6',
						'ml-4 mr-2 flex flex-grow cursor-pointer items-center justify-end text-textSecondaryLight hover:text-textPrimaryLight dark:text-textSecondaryDark dark:hover:text-white',
					)}
				>
					{handleDelete && (
						<div onClick={() => handleDelete('delete')}>
							<CloseIcon />
						</div>
					)}
				</div>
			)}
		</div>
	)
}
